*
{
    margin: 0;
    padding: 0;
}

html,
body
{
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    /* background-color: rgb(153, 132, 132); */
    width: 100%;
    min-height: 100vh;
}

h1
{
    max-width: 50%;
    margin: auto;
}

canvas
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
    width: 100%;
    height: 100vh;
}
